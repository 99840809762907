<template>
  <app-layout>
    <transition name="component-fade" mode="out-in">
      <div
        v-if="showClientForm"
        class="flex flex-col items-center py-4"
        key="form">
        <div class="w-full lg:w-1/2">
          <div class="flex flex-row items-center inline py-4">
            <sa-icon-button
              iconClass="bx bx-left-arrow-alt"
              @click="closeClientForm"
              with-bg />
              <span class="mx-2 text-lg font-bold">
                {{ isNewClient ? 'Crear Cliente' : 'Actualizar Cliente' }}
              </span>
          </div>
          <content-card>
            <client-form
              :client="clientToUpdate"
              :isNewClient="isNewClient"
              @onSaved="handleClientSaved"
              @onCancel="closeClientForm" />
          </content-card>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col items-center py-4"
        key="list">
        <div class="w-full px-12">
          <div class="flex flex-row items-center py-4">
            <h3 class="text-lg font-bold">Clientes</h3>
            <div class="flex flex-row items-center ml-auto">
              <el-button @click="goImportClients" class="config-button" :class="{ 'p-2' : isMobile }">
                <span v-if="!isMobile">Importar</span>
                <i v-if="isMobile" class="text-md bx bx-cog"></i>
              </el-button>
              <sa-button
                type="primary"
                class="items-center px-3 py-3 font-bold rounded"
                @click="createClient">
                Crear cliente
              </sa-button>
            </div>
          </div>
          <div class="flex flex-col space-y-6">
            <content-card>
              <clients-list @onEdit="updateClient"/>
            </content-card>
          </div>
        </div>
      </div>
    </transition>
  </app-layout>
</template>

<script>
import { ContactTypeEnum } from "@/constants";
import responsiveSize from '@/mixins/responsiveSize';

export default {
  name: 'ClientsIndex',
  mixins: [responsiveSize],
  components: {
    ClientForm: () => import('./ClientForm.vue'),
    ClientsList: () => import('./ClientsList.vue'),
    ContentCard: () => import('@/components/molecules/ContentCard.vue'),
    AppLayout: () => import('@/components/layouts/AppLayout.vue'),
  },
  data() {
    return {
      showClientForm: false,
      isNewClient: false,
      clientToUpdate: {
        id: null,
        fullName: '',
        email: '',
        phone: '',
        phoneCountryCodeId: null,
        contactType: ContactTypeEnum.Phone,
        contactDescription: '',
        sendEmailNotifications: false,
        dni: null,
      },
      isMobile: false,
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    handleClientSaved() {
      this.showClientForm = false;
      this.$router.push({ name: "clients.index" });
      this.$toastr.s(this.isNewClient ? 'Cliente creado con éxito' : 'Cliente actualizado con éxito');
    },
    updateClient(client) {
      this.isNewClient = false;
      this.clientToUpdate = client;
      this.showClientForm = true;
    },
    createClient() {
      this.isNewClient = true;
      this.clientToUpdate = {
          id: null,
          fullName: '',
          email: '',
          phone: '',
          phoneCountryCodeId: null,
          contactType: ContactTypeEnum.Phone,
          contactDescription: '',
          sendEmailNotifications: false,
          dni: null,
        };
      this.showClientForm = true;
    },
    closeClientForm() {
      this.showClientForm = false;
      this.isNewClient = false;
      this.clientToUpdate = {
        id: null,
        fullName: '',
        email: '',
        phone: '',
        phoneCountryCodeId: null,
        contactType: ContactTypeEnum.Phone,
        contactDescription: '',
        sendEmailNotifications: false,
        dni: null,
      };
    },
    resize() {
      this.isMobile = this.getPercentageWidth() === '90%';
    },
    goImportClients() {
      const isOwner = this.$store.state.account.accountType === 1;
      const { canViewAdministration } = this.$store.state.account;
      if (isOwner || canViewAdministration) {
        this.$router.push({ name: 'admin.index', params: { slot: 'upload-client-data' } });
      } else {
        this.$swal('Aviso', 'No tienes el permiso para realizar esta acción', 'error');
      }
    },
  },
};
</script>